<template>
  <div v-background-3 class="rounded mb-1 p-3">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <div class="mr-2">
          <AppAvatar :url="collaborateur.collaborateur_avatar" propSize="3rem" ref="avatar"></AppAvatar>
        </div>
        <div class="text-truncate" style="line-height:1.1rem, width:100%;">
          <span class="mr-1">{{ collaborateur.collaborateur_nom }}</span>
          <span>{{ collaborateur.collaborateur_prenom }}</span>
        </div>
        <button-circle
          class="ml-auto"
          name="external-link"
          size="18"
          @click="$emit('show', collaborateur.id)"
        ></button-circle>
      </div>

      <div>{{ $moment(t.connection).format("DD/MM/YYYY HH:MM") }}</div>
    </div>
    <div>
      <small>
        <div>{{ t.ip }}</div>
        <div>{{ t.agent }}</div>
      </small>
    </div>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import AppAvatar from "@/components/bases/Avatar.vue";

export default {
  components: { ButtonCircle, AppAvatar },
  props: ["t", "collaborateurs"],
  data() {
    return {};
  },
  computed: {
    collaborateur: function () {
      const index = this.collaborateurs.findIndex((x) => x.id === this.t.user_id);
      return this.collaborateurs[index];
    },
  },
  methods: {},
  mounted() {},
};
</script>
