<template>
  <div v-background-3 class="p-3 w-100 rounded mb-3">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h5 class="m-0">Utilisateurs</h5>
      <button @click="getEmailAdress()" class="btn btn-primary">Copier les adresses mail</button>
    </div>
    <div class="p-3" v-if="loading">Chargement...</div>
    <div class="d-flex flex-column w-100" v-if="!loading">
      <div v-for="user in users" :key="user.id" class="">
        <UsersManagerItem :user="user"></UsersManagerItem>
      </div>
    </div>
  </div>
</template>
<script>
import UsersManagerItem from "@/components/admin/UsersManagerItem.vue";
export default {
  components: { UsersManagerItem },
  data() {
    return {
      users: [],
      mails: "",
      loading: false,
    };
  },
  computed: {},
  methods: {
    getUsers: function () {
      this.loading = true;
      this.$http
        .get("/users")
        .then((response) => (this.users = response.data))
        .finally(() => (this.loading = false));
    },
    getEmailAdress: function () {
      let text = "";
      this.users.forEach((user) => {
        if (user.collaborateur_email_pro) {
          text = text + user.collaborateur_email_pro + ";";
        }
      });
      navigator.clipboard.writeText(text).then(function () {
        alert("les adresses mail sont copiées dans le presse papier");
      });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
