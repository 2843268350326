<template>
  <div class="d-flex flex-column flex-lg-row">
    <div class="mb-3 mr-lg-3 rounded d-flex flex-column" style="min-width: 250px" :style="{ backgroundColor: color }">
      <div class="m-3 d-flex justify-content-between align-items-center">
        <h6 class="m-0">Liste des roles</h6>
        <div @click="addRole">
          <BaseIcon name="plus" width="18" class="mr-2 mb-1 text-primary cursor-pointer"></BaseIcon>
        </div>
      </div>
      <div
        href="#"
        class="p-2 cursor-pointer d-flex justify-content-between align-items-center border-top"
        :class="role === roleSelected ? 'text-primary' : ''"
        v-for="role in roles"
        :key="role.id"
        @click="selectRole(role)"
      >
        <h6 class="m-0 ml-2">{{ role.name }}</h6>
        <div class="cursor-pointer pb-1 pr-2" @click.stop="deleteRole(role.id)">
          <BaseIcon name="trash" width="14" class="text-primary"></BaseIcon>
        </div>
      </div>
    </div>
    <div class="mb-3 rounded p-3 p-lg-4 w-100" v-if="roleSelected.name" :style="{ backgroundColor: color }">
      <h4 class="m-0">{{ roleSelected.name }}</h4>
      <small>Gestion des permissions pour le role {{ roleSelected.name }}</small>
      <base-select
        inputText="Filtre application"
        v-model.number="appFilter"
        :options="[
          { id: 1, description: 'Qwark' },
          { id: 2, description: 'Spok' },
        ]"
        modeIOS
        class="mb-1"
      ></base-select>
      <base-select inputText="Filtre thème" v-model.number="appFilterTheme" :options="themes" modeIOS></base-select>
      <div class="mt-2">
        <div v-for="permission in permissionsFiltered" :key="permission.id">
          <div class="d-flex rounded p-1 align-items-center">
            <div
              v-if="inArray(permission.name, roleSelected.permissions)"
              class="w-100 d-flex align-items-center justify-content-between mb-1"
            >
              <BaseIcon name="unlock" width="16" class="text-success mb-1 mr-2"></BaseIcon>
              <Checkbox
                id="1"
                class="mt-0"
                :checked="true"
                :iconActive="true"
                @change="revokePermission(permission, roleSelected)"
              ></Checkbox>
              <div class="w-100 mr-1 ml-1" style="line-height: 1rem">
                <div>{{ permission.description }}</div>
                <div class="text-muted">
                  <small>{{ permission.name }}</small>
                </div>
              </div>
              <div class="cursor-pointer ml-2" @click="deletePermission(permission.id)">
                <BaseIcon name="trash" width="12" class="text-muted"></BaseIcon>
              </div>
            </div>
            <div v-else class="w-100 d-flex justify-content-between align-items-center mb-1">
              <BaseIcon name="lock" width="16" class="text-danger mb-1 mr-2"></BaseIcon>
              <Checkbox
                id="1"
                class="mt-0"
                :checked="false"
                :iconActive="true"
                @change="assignPermission(permission, roleSelected)"
              ></Checkbox>
              <div class="w-100 mr-1 ml-1" style="line-height: 1rem">
                <div>{{ permission.description }}</div>
                <div class="text-muted">
                  <small>{{ permission.name }}</small>
                </div>
              </div>
              <div class="cursor-pointer ml-2" @click="deletePermission(permission.id)">
                <BaseIcon name="trash" width="12" class="text-muted"></BaseIcon>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <base-input v-model="name" inputText="Name" :errors="feedback.name"></base-input>
          <base-input v-model="description" inputText="Description" :errors="feedback.description"></base-input>
          <base-input v-model="details" inputText="Details" :errors="feedback.details"></base-input>
          <button class="btn btn-primary btn-sm mt-3 ml-auto" @click="addPermission">Ajouter une permission</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Checkbox from "@/components/bases/Checkbox.vue";
import BaseIcon from "@/components/bases/Icon.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";

export default {
  components: { Checkbox, BaseIcon, BaseInput, BaseSelect },
  data() {
    return {
      feedback: {},
      loading: false,
      roles: {},
      permissions: {},
      roleSelected: {},
      name: null,
      description: null,
      details: null,
      appFilter: 1,
      appFilterTheme: 1,
      themes: [],
    };
  },
  mounted() {
    this.getRoles();
    this.getPermissions();
  },
  methods: {
    getRoles: function () {
      this.loading = true;
      this.$http
        .get("/users/roles")
        .then((response) => {
          this.roles = response.data.Roles;
        })
        .finally(() => (this.loading = false));
    },
    getPermissions: function () {
      this.loading = true;
      this.$http
        .get("/users/permissions")
        .then((response) => {
          let data = response.data.Permissions;

          for (let index = 0; index < data.length; index++) {
            data[index].active = false;
          }

          this.permissions = data;
          this.themes = response.data.data;
        })
        .finally(() => (this.loading = false));
    },
    selectRole: function (role) {
      this.roleSelected = role;
    },
    assignPermission: function (perm, role) {
      this.loading = true;
      this.$http
        .post("/users/roles/permission/assign", {
          role: role.name,
          permission: perm.name,
        })
        .then(() => {
          role.permissions.push(perm);
        })
        .finally(() => (this.loading = false));
    },
    revokePermission: function (perm, role) {
      this.loading = true;
      this.$http
        .post("/users/roles/permission/revoke", {
          role: role.name,
          permission: perm.name,
        })
        .then(() => {
          const index = role.permissions.findIndex((x) => x.id === perm.id);
          role.permissions.splice(index, 1);
        })
        .finally(() => (this.loading = false));
    },
    inArray: function (value, array) {
      if (value && array) {
        var length = array.length;
        for (var i = 0; i < length; i++) {
          if (array[i].name === value) return true;
        }
        return false;
      }
      return false;
    },
    addPermission: function () {
      this.loading = true;
      this.$http
        .post("/users/permissions", {
          permission: this.name,
          description: this.description,
          details: this.details,
          app_id: 1,
        })
        .then((response) => {
          this.permissions.push(response.data);
        })
        .finally(() => (this.loading = false));
    },
    addRole: function () {
      let val = prompt("Saisir le role");
      this.loading = true;
      this.$http
        .post("/users/roles", {
          role: val,
        })
        .then((response) => {
          this.roles.push(response.data);
        })
        .finally(() => (this.loading = false));
    },
    deleteRole: function (id) {
      if (confirm("Etes vous certain de vouloir supprimer ce role ?")) {
        this.loading = true;
        this.$http
          .delete("/users/roles/" + id)
          .then(() => {
            const index = this.roles.findIndex((x) => x.id === id);
            this.roles.splice(index, 1);
          })
          .finally(() => (this.loading = false));
      }
    },
    deletePermission: function (id) {
      if (confirm("Etes vous certain de vouloir supprimer cette permission ?")) {
        this.loading = true;
        this.$http
          .delete("/users/permissions/" + id)
          .then(() => {
            const index = this.permissions.findIndex((x) => x.id === id);
            this.permissions.splice(index, 1);
          })
          .finally(() => (this.loading = false));
      } else {
        // Code à éxécuter si l'utilisateur clique sur "Annuler"
      }
    },
  },
  computed: {
    color: function () {
      return this.$store.getters["colors/colors"].color3;
    },
    permissionsFiltered: function () {
      let rep = this.permissions;

      if (this.appFilter) {
        rep = rep.filter((perm) => perm.app_id === this.appFilter);
      }

      if (this.appFilterTheme) {
        rep = rep.filter((perm) => perm.theme_id === this.appFilterTheme);
      }

      return rep;
    },
  },
};
</script>
<style></style>
