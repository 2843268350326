<template>
  <div v-background-1 class="mb-1 d-flex p-2 rounded align-items-center">
    <AppAvatar :url="user.collaborateur_avatar" propSize="2.3rem" ref="avatar" class="mr-2"></AppAvatar>
    <div class="d-flex align-items-center">
      <div class="mr-2">{{ user.collaborateur_nom }} - {{ user.collaborateur_prenom }}</div>
      <small>
        <div class="d-flex flex-wrap">
          <div class="mr-2 p-1 rounded" v-for="role in user.roles" :key="role.id" v-background-2>{{ role.name }}</div>
        </div>
      </small>
    </div>
  </div>
</template>
<script>
import AppAvatar from "@/components/bases/Avatar.vue";

export default {
  components: { AppAvatar },
  props: ["user"],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
