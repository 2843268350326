<template>
  <div class="p-3 pl-4 pr-4 rounded mb-3" v-background-3>
    <h6 class="border-bottom pb-2">Menu</h6>
    <div class="text-nowrap cursor-pointer mb-1" @click="menuTab('roles')" :class="{ 'text-danger': tab == 'roles' }">
      Gestion des Roles
    </div>
    <div class="text-nowrap cursor-pointer mb-1" @click="menuTab('users')" :class="{ 'text-danger': tab == 'users' }">
      Utilisateurs
    </div>
    <div class="text-nowrap cursor-pointer" @click="menuTab('track')" :class="{ 'text-danger': tab == 'track' }">
      Connections
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["tab"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    menuTab: function (menuTab) {
      this.$emit("menuTab", menuTab);
    },
  },
};
</script>
<style></style>
