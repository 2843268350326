<template>
  <div>
    <div v-background-3 class="p-3 w-100 rounded mb-3">Registre des connections</div>

    <div v-background-3 class="p-3 w-100 rounded mb-3">
      <apexchart type="area" height="360" :options="options" :series="chartData"></apexchart>
    </div>

    <div class="mb-3">
      <button class="btn btn-primary mr-3" @click="tab = 'today'" :class="{ active: tab == 'today' }">
        Aujourd'hui
      </button>
      <button class="btn btn-primary" @click="tab = 'before'" :class="{ active: tab == 'before' }">Avant</button>
    </div>
    <div v-if="!loading && !loading2">
      <div v-if="tab == 'today'">
        <div v-for="t in connectionsToday" :key="t.id">
          <users-tracker-item @show="openCollab" :collaborateurs="collaborateurs" :t="t"></users-tracker-item>
        </div>
      </div>
      <div v-if="tab == 'before'">
        <div v-for="t in connectionsBefore" :key="t.id">
          <users-tracker-item @show="openCollab" :collaborateurs="collaborateurs" :t="t"></users-tracker-item>
        </div>
      </div>
    </div>
    <div v-if="loading || loading2">Chargement...</div>
  </div>
</template>
<script>
import UsersTrackerItem from "@/components/admin/UsersTrackerItem.vue";
import { mapActions } from "vuex";
//var fr = require("apexcharts/dist/locales/fr.json");

export default {
  components: { UsersTrackerItem },
  data() {
    return {
      track: [],
      collaborateurs: [],
      loading: false,
      loading2: false,
      tab: "today",
      nbrJoursGraph: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  },
  computed: {
    connectionsToday() {
      return this.track.filter((t) => this.$moment(t.connection).isSame(this.$moment(), "day"));
    },
    connectionsBefore() {
      return this.track.filter((t) => this.$moment(t.connection).isBefore(this.$moment(), "day"));
    },
    chartData() {
      let out = this.nbrJoursGraph;
      const f = "YYYY-MM-DD";

      this.track.forEach((x) => {
        for (let i = 0; i < out.length; i++) {
          if (this.$moment(x.connection).format(f) == this.$moment().subtract(i, "days").format(f)) out[i]++;
        }
      });

      return [
        {
          data: out.reverse(),
        },
      ];
    },
    options() {
      let cat = [];
      for (let i = 0; i < this.nbrJoursGraph.length; i++) {
        cat.push(this.$moment().subtract(i, "days").format("YYYY-MM-DD"));
      }

      if (this.chartData) {
        return {
          colors: ["#EB9D00"],
          chart: {
            background: "transparent",
            toolbar: {
              show: false,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              },
              autoSelected: "zoom",
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          theme: {
            mode: this.$store.getters["colors/darkMode"] ? "dark" : "light",
          },
          stroke: {
            width: 4,
            curve: "smooth",
          },
          xaxis: {
            categories: cat.reverse(),
          },
          fill: {
            opacity: 0.8,
          },
          markers: {
            size: 4,
            strokeColors: "#fff",
            strokeWidth: 0,
            hover: {
              size: 7,
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " collaborateurs";
              },
            },
          },
        };
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      _getCollaborateursAll: "collaborateurs/getCollaborateursAll",
    }),
    getTrack: function () {
      this.loading = true;
      this.$http
        .get("/track")
        .then((response) => (this.track = response.data))
        .finally(() => (this.loading = false));
    },
    getCollaborateursAll: function () {
      this.loading2 = true;
      this._getCollaborateursAll()
        .then((data) => (this.collaborateurs = data))
        .finally(() => (this.loading2 = false));
    },
    openCollab(id) {
      this.$router.push({
        name: "sirh_collaborateur_infos",
        params: { id: id },
      });
    },
  },
  mounted() {
    this.getTrack();
    this.getCollaborateursAll();
  },
};
</script>
